angular.module('raw', [])
.controller('servicesVM', function($scope){
  $scope.isStudent = false;
  const price = (a, b) => $scope.isStudent ? a : b;
  const init = () => {

    $scope.proofreading = {
      prices: {
        standard: {
          base: price('1.50', '5'),
          bulk: price('1', '4')
        },
        rapid: {
          base: price('3', '12'),
          bulk: price('5', '8')
        }
      }
    }

    $scope.contentEditing = {
      prices: {
        standard: {
          base: price('4', '10'),
          bulk: price('2.50', '8')
        },
        rapid: price('8', '15')
      }
    }
  }

  init()

  $scope.$watch('isStudent', init)
})


function checkoutForm($){
  // Fields
  const service       = $('input[name="item_meta[86]"]')
  const turnaround    = $('input[name="item_meta[93]"]')
  const studentBox    = $('input[name="item_meta[87][]"]')
  const pageCount     = $('input[name="item_meta[92]"]')
  const pricePerPage  = $('input[name="item_meta[97]"]')
  const total         = $('input[name="item_meta[95]"]')
  const receiptLine   = $('input[name="item_meta[98]"]')
  const submitBtn     = $('input[type="submit"]')

  const updateTotal   = () => {
    let updatedPagePrice, updatedTotal, overMax;

    let isStudent          = studentBox.is(':checked')
    let selectedService    = service.filter(':checked').val()
    let selectedTurnaround = turnaround.filter(':checked').val()
    let currentPageCount   = pageCount.val()
    let priceIndex         = currentPageCount >= 50 ? 1 : 0

    if(selectedService === 'Proofreading'){
      updatedPagePrice = getProofreadingPrices(isStudent, selectedTurnaround)
      updatedPagePrice = updatedPagePrice[priceIndex]
    } else {
      updatedPagePrice = getContentEditingPrices(isStudent, selectedTurnaround)
      updatedPagePrice = updatedPagePrice[priceIndex]
    }

    receiptLine.val(`${selectedService} ${currentPageCount} pages - ${selectedTurnaround} $ ${updatedPagePrice} per page`)

    overMax = selectedService === 'Content Editing'
        && selectedTurnaround === '24 Hours'
        && currentPageCount > 49

    if(overMax){
      submitBtn.attr('disabled', 'disabled').toggleClass('disabled')
    } else {
      submitBtn.removeAttr('disabled').toggleClass('disabled')
    }

    updatedTotal = updatedPagePrice * currentPageCount

    pricePerPage.val(updatedPagePrice)
    total.val(updatedTotal)
  }

  // Events
  service.change(updateTotal)
  turnaround.change(updateTotal)
  pageCount.change(updateTotal)
  studentBox.change(updateTotal)
  
  // Run Once for Default Values.
  updateTotal()
}

// Return tuple [base price, bulk price].
// content editing returns the same tuple for base & bulk.

function getProofreadingPrices(isStudent, turnaroundTime){
  if(turnaroundTime === '24 Hours'){
    return isStudent ? [3, 5] : [5, 4]
  }
  return isStudent ? [1.5, 1] : [5, 4]
}

function getContentEditingPrices(isStudent, turnaroundTime){
  if(turnaroundTime === '24 Hours'){
    return isStudent ? [8, 15] : [8, 15]
  }
  return isStudent ? [4, 2.5] : [10, 8]
}
